


import { ROOT_ACTIONS } from "@/store/actions";
import { User } from "@/store/user/types";
import { Component, Vue, Watch } from "vue-property-decorator";
import { USER_GETTERS } from "@/store/user/getters";
import { firestore } from '@/firebase';
import { getDocs, collection, query, where, Timestamp, addDoc } from "firebase/firestore";
import * as emails from '@/bin/emails'

@Component({
  components: {
    NewUserDialog: () => import("@/components/dialogs/NewUserDialog.vue"),
  },
})
export default class Index extends Vue {

  get isAdmin(): boolean {
    return this.$store.getters[USER_GETTERS.ACTING_USER].type == 'ADMIN'
  }

  handleClick(user: User) {
    this.$router.push(`/admin/users/${user.id}`)
  }

  users: User[] = [];

  loading = {
    users: false,
  };

  searchInput = '';

  dialogs = {
    newUser: false,
  };

  headers = [
    { text: "Naam", value: "firstname" },
    { text: "Famillienaam", value: "lastname" },
    { text: "Email", value: "email" },
    { text: 'Taal', value: 'language' },
    { text: 'Datum toegevoegd', value: 'createdAt' },
  ];

  groups = [
    { text: "Admins", value: "ADMIN" },
    { text: "Coaches", value: "COACH" },
    { text: "Deelnemers", value: "PARTICIPANT" },
    { text: "Bedrijfscontacten", value: "COMPANY_CONTACT" },
    { text: "Interim managers", value: "INTERIM_MANAGER" },
  ];

  sortBy: string[] = [];
  sortDesc: boolean[] = [];

  mounted() {
    const sortBy = localStorage.getItem('sortBy');
    const sortDesc = localStorage.getItem('sortDesc');
    if (sortBy) this.sortBy = JSON.parse(sortBy)
    if (sortDesc) this.sortDesc = JSON.parse(sortDesc)
  }

  @Watch('sortBy')
  onOptionChange() {
    localStorage.setItem('sortBy', JSON.stringify(this.sortBy))
  }

  @Watch('sortDesc')
  onChange() {
    if (this.sortDesc) {
      localStorage.setItem('sortDesc', JSON.stringify(this.sortDesc))
    } else {
      localStorage.removeItem('sortDesc');
    }
  }

  @Watch("$route.query", { immediate: true })
  async getUsersFromGroup() {
    if (!this.$route.query.group) {
      this.$router.push({
        path: this.$route.fullPath,
        query: {
          group: 'admin'
        }
      })
      return;
    }
    this.$store.dispatch(ROOT_ACTIONS.SET_BREADCRUMBS, [{ text: 'Gebruikers', target: '/' }])

    try {
      this.loading.users = true;
      const group = this.$route.query.group as string;
      const userCollection = collection(firestore, 'users');

      let users = await getDocs(query(userCollection, where('type', '==', group.toUpperCase())));

      this.users = users.docs.map(doc => {
        return {
          ...doc.data(),
          id: doc.id,
        } as User
      })

    } catch (error) {
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: "error",
        text: error,
      });
    } finally {
      this.loading.users = false;
    }

    this.loading.users = false;
  }

  formatDate(isoString: Timestamp): string {
    return isoString.toDate().toLocaleDateString();
  }
}
